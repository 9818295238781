<template>
    <div>
        <div>
            
            <!-- Sticky search header -->
            <div
                class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 bg-white dark:bg-slate-900 border-b border-white/5 px-4 shadow-sm sm:px-6 lg:px-8"
            >
                <Ui2DashSidebar />

                <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <!-- <form
                        class="flex flex-1 items-center justify-center"
                        action="#"
                        method="GET"
                    >
                        <label for="search-field" class="sr-only">Search</label>
                        <div class="relative w-full">
                            <UIcon
                                name="i-heroicons-magnifying-glass"
                                class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-slate-500"
                                aria-hidden="true"
                            />
                            <input
                                id="search-field"
                                class="block h-full w-full border-0 bg-white dark:bg-slate-900 py-0 pl-8 pr-0 focus:ring-0 sm:text-sm"
                                placeholder="Search..."
                                type="search"
                                name="search"
                            />
                        </div>
                    </form> -->
                </div>
                <div>
                    <!-- <StoreCartPopover /> -->
                    <!-- Might be causing a memory leak -->
                    <NuxtLink to="/cart">
                        <UButton
                            icon="i-heroicons-shopping-bag"
                            size="lg"
                            color="white"
                            variant="ghost"
                        />
                    </NuxtLink>
                </div>
                <div>
                    <LazyUiColorModeButton />
                </div>
            </div>

            <main>
                <div class="mx-auto max-w-7xl px-6">
                    <UContainer>
                        <slot />
                    </UContainer>
                </div>
            </main>
            <!-- Activity feed -->
            <!-- <NuxtLinkside class="bg-black/10 lg:fixed lg:bottom-0 lg:right-0 lg:top-16 lg:w-96 lg:overflow-y-auto lg:border-l lg:border-white/5">
        <header class="flex items-center justify-between border-b border-white/5 px-4 py-4 sm:px-6 sm:py-6 lg:px-8">
          <h2 class="text-base font-semibold leading-7 text-white">Activity feed</h2>
          <NuxtLink to="#" class="text-sm font-semibold leading-6 text-indigo-400">View all</NuxtLink>
        </header>
        <ul role="list" class="divide-y divide-white/5">
          <li v-for="item in activityItems" :key="item.commit" class="px-4 py-4 sm:px-6 lg:px-8">
            <div class="flex items-center gap-x-3">
              <img :src="item.user.imageUrl" alt="" class="h-6 w-6 flex-none rounded-full bg-slate-800" />
              <h3 class="flex-auto truncate text-sm font-semibold leading-6 text-white">{{ item.user.name }}</h3>
              <time :datetime="item.dateTime" class="flex-none text-xs text-slate-600">{{ item.date }}</time>
            </div>
            <p class="mt-3 truncate text-sm text-slate-500">
              Pushed to <span class="text-slate-400">{{ item.projectName }}</span> (<span class="font-mono text-slate-400">{{ item.commit }}</span> on <span class="text-slate-400">{{ item.branch }}</span
              >)
            </p>
          </li>
        </ul>
      </NuxtLinkside> -->
        </div>
    </div>
</template>

<script setup lang="ts">
// definePageMeta({
//  middleware: "auth"
// })

// useHead({
//   htmlAttrs: {
//       class: "bg-slate-900",
//   },
// });

import { ref } from 'vue'

const route = useRoute()
//const navigation = [
// { name: 'Projects', href: '#', icon: FolderIcon, current: false },
// { name: 'Deployments', href: '#', icon: ServerIcon, current: true },
// { name: 'Activity', href: '#', icon: SignalIcon, current: false },
//   {
//     label: "Dashboard",
//     to: "/dashboard",
//   },
//   {
//     label: "[organizations name] Domain Pool",
//     to: "/dashboard/domains",
//   },
//   {
//     label: "Default Domain WHOIS",
//     to: "/dashboard/domains/whois",
//   },
//   {
//     label: "Register Domains",
//     to: "/dashboard/domains/register",
//   },
//   {
//     label: "Transfer Domains",
//     to: "/dashboard/domains/transfers",
//   },
//   {
//     label: "Billing",
//     to: "/dashboard/billing",
//   },
//   {
//     label: "Reports",
//     to: "/dashboard/reports",
//   },
//   {
//     label: "Registrars (admin/SaaS only)",
//     to: "/dashboard/registrars",
//   },
//   {
//     label: "Expiring Domains (admin only)",
//     to: "/dashboard/domains/expiring",
//   },
//   {
//     label: "Organizations (admin only)",
//     to: "/dashboard/admin/organizations",
//   },
//   {
//     label: "Users (admin only)",
//     to: "/dashboard/admin/users",
//   },
//   {
//     label: "Price List (admin only)",
//     to: "/dashboard/admin/price-list",
//   },

//   {
//     label: "Settings",
//     to: "/dashboard/settings",
//   },
//   {
//     label: "Help",
//     to: "/dashboard/help",
//   },
// ];
const clients = [
    { id: 1, name: 'PHS', href: '#', initial: 'P', current: false },
    { id: 2, name: 'NSK', href: '#', initial: 'N', current: false },
    { id: 3, name: 'MS', href: '#', initial: 'M', current: false },
]
const statuses = {
    offline: 'text-slate-500 bg-slate-100/10',
    online: 'text-green-400 bg-green-400/10',
    error: 'text-rose-400 bg-rose-400/10',
}

const sidebarOpen = ref(false)

function getActivePage(_href: string) {
    if (_href == route.path) return true
    else return false
}
</script>

<template>
    <div>
        <UButton
            size="xl"
            color="gray"
            variant="ghost"
            icon="i-heroicons-bars-3"
            @click="isOpen = true"
        />

        <USlideover v-model="isOpen" side="left">
            <UCard
                class="flex flex-col flex-1 overflow-hidden overflow-y-scroll"
                :ui="{
                    body: { base: 'flex-1' },
                    ring: '',
                    divide: 'divide-y divide-gray-100 dark:divide-gray-800',
                }"
            >
                <template #header>
                    <div class="flex items-center justify-between">
                        <div class="flex h-16 shrink-0 items-center">
                            <NuxtLink to="/dashboard"
                                ><nuxt-img
                                    class="w-12 h-12"
                                    src="/logos/default.svg"
                                    alt="Domain Pool Logo"
                            /></NuxtLink>
                            <span class="sr-only">DP Logo</span>
                        </div>
                        <UButton
                            size="xl"
                            color="gray"
                            variant="ghost"
                            icon="i-heroicons-x-mark-20-solid"
                            class="-my-1"
                            @click="isOpen = false"
                        />
                    </div>
                </template>

                <div v-if="isLoad">
                    <UVerticalNavigation :links="finalLinks">
                        <template #default="{ link }">
                            <div
                                @click="isOpen = false"
                                class="relative text-left w-full m-4"
                            >
                                <div class="mb-2">
                                    {{ link.label }}
                                </div>
                                <UVerticalNavigation
                                    v-if="link.children"
                                    :links="link.children"
                                    :ui="{
                                        wrapper:
                                            'border-s border-gray-200 dark:border-gray-800 space-y-2',
                                        base: 'group block border-s -ms-px lg:leading-6',
                                        padding: 'ps-4',
                                        rounded: '',
                                        font: '',
                                        ring: '',
                                        active: 'text-primary-500 dark:text-primary-400 border-current font-semibold',
                                        inactive:
                                            'border-transparent hover:border-gray-400 dark:hover:border-gray-500 text-slate-700 hover:dark:text-slate-100 dark:text-slate-400 dark:hover:text-slate-600 dark:text-slate-300',
                                    }"
                                >

                                </UVerticalNavigation>
                            </div>
                        </template>
                    </UVerticalNavigation>
                </div>

                <template #footer>
                    <NuxtLink
                        to="/dashboard/profile"
                        class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6"
                    >
                        <!-- <UAvatar
                            src="https://avatars.githubusercontent.com/u/739984?v=4"
                            alt="Avatar"
                        /> -->
                        <span class="sr-only">Your profile</span>
                        <span aria-hidden="true">Your profile</span>
                    </NuxtLink>

                    <NuxtLink to="/" v-if="isAuthenticated" @click="signout"
                      class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6">
                        Signout
                    </NuxtLink>

                </template>
            </UCard>
        </USlideover>
    </div>
</template>

<script setup lang="ts">
const isOpen = ref(false)
import { useAuth0 } from '@auth0/auth0-vue'
const auth0 = process.client ? useAuth0() : undefined
let userRoles = [];
const isLoad = ref(false);
const config = useRuntimeConfig()


function signout() {
    if (process.client) {
        auth0?.logout({ logoutParams: { returnTo: window.location.type } })
    }
}


const isAuthenticated = computed(() => {
    return auth0?.isAuthenticated.value
})


if (auth0?.isAuthenticated.value) {
  const userInfo = JSON.parse(JSON.stringify(auth0?.user.value))

  if (typeof userInfo[config.public.auth0UserRolesResponseKey] !== 'undefined')
    userRoles = userInfo[config.public.auth0UserRolesResponseKey]

}
let links = [
    {
        label: 'Dashboard',
        to: '/dashboard',
        roles: ['Owner','Admin','Staff','Organization Admin','Pro Organization Admin','Pro Organization User','SaaS Admin','SaaS User','SaaS Client Admin (FREE PLAN)','SaaS Client Admin (PRO PLAN)', 'SaaS Client User (PRO PLAN)']
    },

    {
        label: 'Domain Management',
        children: [
            {
                label: 'Your Domain Pool',
                to: '/dashboard/domains',
                roles: ['Owner','Admin','Organization Admin']
            },
            {
                label: 'Register Domains',
                to: '/search-for-domains',
                roles: ['Owner','Admin','Organization Admin']
            },
            {
                label: 'Transfer Domains',
                to: '/search-for-domains',
                roles: ['Owner','Admin','Organization Admin']
            },
            {
                label: 'Add Local Domain Registration',
                to: '/dashboard/local-domain-registration-service',
                roles: ['Owner','Admin','Organization Admin']
            },
            {
                label: 'WHOIS',
                to: '/dashboard/domains/whois',
                roles: ['Owner','Admin','Organization Admin']
            },
        ],
    },

    {
        label: 'Your Organizations',
        to: '/dashboard/profile/organizations',
        roles: ['Owner','Admin','Staff','Organization Admin','Pro Organization Admin','Pro Organization User','SaaS Admin','SaaS User','SaaS Client Admin (FREE PLAN)','SaaS Client Admin (PRO PLAN)', 'SaaS Client User (PRO PLAN)'],
    },

    // {
    //     label: 'Reports',
    //     to: '/dashboard/reports/',
    // },

    {
        label: 'Settings',
        children: [
            {
                label: 'Billing',
                to: '/dashboard/billing',
                roles: ['Owner','Admin','Organization Admin']
            },
            {
                label: 'Invoices',
                to: '/dashboard/billing/invoices',
                roles: ['Owner','Admin','Organization Admin']
            },
            {
                label: 'Subscriptions',
                to: '/dashboard/billing/subscriptions',
                roles: ['Owner','Admin','Organization Admin']
            },
            // {
            //     label: 'Plan',
            //     to: '/dashboard/billing/plan',
            //     roles: ['Owner','Admin','Organization Admin']
            // },
            // {
            //     label: 'Settings',
            //     to: '/dashboard/settings',
            //     roles: ['Owner','Admin','Organization Admin']
            // },
            // {
            //     label: 'Help',
            //     to: '/dashboard/help',
            //     roles: ['Owner','Admin','Organization Admin']
            // },
        ],
    },

    {
        label: 'Support',
        to: '/dashboard/support',
        roles: ['Owner','Admin','Staff','Organization Admin','Pro Organization Admin','Pro Organization User','SaaS Admin','SaaS User','SaaS Client Admin (FREE PLAN)','SaaS Client Admin (PRO PLAN)', 'SaaS Client User (PRO PLAN)']
    },

    {
        label: 'SaaS Nav',
        roles: ['Owner','Admin'],
        children: [
            {
                label: 'Expiring Domains',
                to: '/dashboard/domains/expiring',
                roles: ['Owner','Admin']
            },
            {
                label: 'Registrars',
                to: '/dashboard/registrars',
                roles: ['Owner','Admin']
            },
        ],
    },

    {
        label: 'Admin Nav',
          roles: ['Owner','Admin'],
        children: [
            // NOT IN USE YET FOR ADMINS
            // {
            //   label: 'Dashboard',
            //   to: '/dashboard/admin',
            // },
            // NOT IN USE YET FOR ADMINS
            // {
            //   label: 'Registrars',
            //   to: '/dashboard/admin/registrars',
            // },
            {
                label: 'Organizations',
                to: '/dashboard/admin/organizations',
                roles: ['Owner','Admin']
            },
            {
                label: 'Users',
                to: '/dashboard/admin/users',
                roles: ['Owner','Admin']
            },
            {
                label: 'Price List',
                to: '/dashboard/admin/price-list',
                roles: ['Owner','Admin']
            },
            {
                label: 'Activity',
                to: '/dashboard/admin/activity',
                roles: ['Owner','Admin']
            },
            {
                label: 'Activity (Table)',
                to: '/dashboard/admin/activity-table',
                roles: ['Owner','Admin']
            },
            {
                label: 'DP Config',
                to: '/dashboard/admin/dp-config',
                roles: ['Owner','Admin']
            },
            // {
            //     label: 'Price List Friends',
            //     to: '/dashboard/admin/price-list-friends',
            //     roles: ['Owner','Admin']
            // },
            // {
            //     label: 'Price List Resellers',
            //     to: '/dashboard/admin/price-list-resellers',
            //     roles: ['Owner','Admin']
            // }
        ],
    },
]

let finalLinks = [];
let index = 0;
links.forEach((link,i) => {
  let checkChildren = false;
  if (link?.roles && link?.roles.length > 0) {

    if (userRoles.length > 0 && link.roles.includes(userRoles[0])) {
        finalLinks.push(link);
        checkChildren = true;

    }
  }else{
        finalLinks.push(link);
        checkChildren = true;
  }


  if(checkChildren == true){

    if(  typeof finalLinks[index].children !== 'undefined'){

      for(let j = 0; j < finalLinks[index].children.length; j++){
        if (finalLinks[index].children[j]?.roles && finalLinks[index].children[j]?.roles.length > 0) {

          if(userRoles.length == 0){
            finalLinks[index].children.splice(j,1)
            j--;
          }

          if (!finalLinks[index].children[j]?.roles.includes(userRoles[0])) {
              finalLinks[index].children.splice(j,1)
              j--;
          }
        }

      }


    }
    index++;

  }


})

isLoad.value= true;



</script>
